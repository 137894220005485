<template>
  <BookingPosition
    displayedCard="inbox"
  >
    <div class="wrapper w100" v-if="this.position">
      <div class="navbar d-flex">
        <div>
          <b-link :to="{ name: 'positions-inbox' }">&lt; Volver al inbox</b-link>
        </div>
        <div>
          <b-button
            variant="secondary"
            :disabled="!previous"
            :to="previous"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-left.svg')">
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!next"
            :to="next"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-right.svg')">
          </b-button>
        </div>
      </div>
      <DisplayBookingPosition
        :position="position"
      />
    </div>
    <div class="buttons">
      <b-button variant="secondary" v-b-modal.decline-position>Declinar</b-button>
      <b-modal
        id="decline-position"
        modal-class="user-information-modal booking-modals centered"
        size="sm"
        centered
        hide-header
        hide-footer
      >
        <b-row>
          <b-col>
            <h4 class="text-center">¿Deseas declinar la oferta?</h4>
          </b-col>
        </b-row>
        <b-row class="buttons">
          <b-col>
            <b-button
              class="float-right"
              variant="secondary"
              @click="$bvModal.hide('decline-position')"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="float-left"
              variant="primary"
              @click="declineContactedTechnicianAsTechnician"
            >
              Declinar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-button variant="primary" v-b-modal.accept-position>Solicitar</b-button>
      <b-modal
        id="accept-position"
        modal-class="user-information-modal booking-modals centered"
        size="sm"
        centered
        hide-header
        hide-footer
      >
        <b-row>
          <b-col>
            <h4 class="text-center">¿Deseas solicitar la oferta?</h4>
          </b-col>
        </b-row>
        <b-row class="buttons">
          <b-col>
            <b-button
              class="float-right"
              variant="secondary"
              @click="$bvModal.hide('accept-position')"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="float-left"
              variant="primary"
              @click="acceptContactedTechnicianAsTechnician"
            >
              Solicitar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </BookingPosition>
</template>
<script>
import BookingPosition from '@/views/BookingPosition.vue'
import DisplayBookingPosition from '@/components/BookingPosition/DisplayBookingPosition.vue'

export default {
  name: 'BookingPositionInbox',
  components: { BookingPosition, DisplayBookingPosition },
  data () {
    return {
      positions: [],
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    position () {
      return this.positions[this.getPositionIndex()]
    },
    next () {
      const index = 1 + this.getPositionIndex()
      if (this.positions.length === index) {
        return null
      }
      return {
        name: 'positions-inbox-position',
        params: {
          id: this.positions[index].getId
        }
      }
    },
    previous () {
      const index = this.getPositionIndex()
      if (index === 0) {
        return null
      }
      return {
        name: 'positions-inbox-position',
        params: {
          id: this.positions[index - 1].getId
        }
      }
    }
  },
  methods: {
    getPositionIndex () {
      return this.positions.findIndex(position => position.getId === this.$route.params.id)
    },
    loadData () {
      this.$store.dispatch('getPositionInbox')
        .then((res) => {
          this.positions = res
        })
    },
    declineContactedTechnicianAsTechnician () {
      const data = {
        bookingPositionId: this.$route.params.id,
        technicianAccepted: false
      }

      this.$store.dispatch('updateContactedTechnicianAsTechnician', data)
        .then(() => {
          this.$store.dispatch('showConfirmationModal')
            .then(() => {
              this.$bvModal.hide('decline-position')
              this.unlockScreen()
              this.$router.push({
                name: 'positions-rejected-position',
                params: {
                  id: this.$route.params.id
                }
              })
            })
        })
    },
    acceptContactedTechnicianAsTechnician () {
      const data = {
        bookingPositionId: this.$route.params.id,
        technicianAccepted: true
      }

      this.lockScreen()

      this.$store.dispatch('updateContactedTechnicianAsTechnician', data)
        .then(() => {
          this.$store.dispatch('showConfirmationModal')
            .then(() => {
              this.$bvModal.hide('accept-position')
              this.unlockScreen()
              this.$router.push({
                name: 'positions-pending-position',
                params: {
                  id: this.$route.params.id
                }
              })
            })
        })
    }
  }
}
</script>
<style lang="less">
  @import '../components/Modal/userInformationModal.less';
</style>
<style lang="less" scoped>
  @import '../style/text.less';
  @import "../style/responsive";

  .position-container {
    .wrapper {
      .navbar {
        padding-bottom: 1rem;
        padding-top: 0;
        .btn.btn-secondary {
          padding: 0.15rem 1rem;
          margin-left: 0.4rem;
        }
        a {
          color: #ffffff;
        }
      }
    }
    .buttons {
      text-align: right;
      background-color: @color_dark_blue;
      padding: 0 1.5rem 1.5rem 1.5rem;
      button {
        margin-left: 2rem;
        @media (max-width: @break_xs) {
          margin-left: 0;
        }
      }

      @media (max-width: @break_xs) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .modal-body {
    font-family: @font_fira;
    text-align: center;
    h4 {
      margin-bottom: 3rem;
    }
  }
</style>
